<template>
  <svg  viewBox="0 0 160 175" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M80 174.191C124.183 174.191 160 169.96 160 164.74C160 159.52 116.082 157.989 71.8987 157.989C27.716 157.989 0 159.52 0 164.74C0 169.96 35.8172 174.191 80 174.191Z" fill="#F7F8FC"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M26.3291 91.1533H107.342L85.0633 117.482H4.05066L26.3291 91.1533Z" fill="#E5E7E9"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M132.762 60.734L99.7222 45.3272L93.9682 33.7052L127.008 49.1121L132.762 60.734Z" fill="#E5E7E9"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M132.762 60.734L63.011 28.2084L32.1974 94.2886L101.949 126.814L132.762 60.734Z" fill="#EDEEF2"/>
    <path d="M63.011 28.2085L31.8065 13.6576L0.992832 79.7378L32.1973 94.2887L63.011 28.2085Z" fill="url(#paint0_linear_4094_28523)"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M31.8065 13.6576L63.0111 28.2085L94.7005 27.3427L63.4959 12.7918L31.8065 13.6576Z" fill="#F8F9FB"/>
    <path d="M107.342 91.1533H26.3291V164.065H107.342V91.1533Z" fill="url(#paint1_linear_4094_28523)"/>
    <mask id="mask0_4094_28523" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="107" y="91" width="35" height="74">
      <path d="M141.772 91.1533H107.342V164.065H141.772V91.1533Z" fill="white"/>
    </mask>
    <g mask="url(#mask0_4094_28523)">
      <path d="M107.342 91.1533H141.772V164.065H107.342V91.1533Z" fill="#E0E3E9"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M141.772 91.1533H107.342L115.443 127.609L155.949 123.558L141.772 91.1533Z" fill="#D5D7DE"/>
    </g>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M141.772 91.1533H107.342L125.57 117.482H160L141.772 91.1533Z" fill="#F8F9FB"/>
    <defs>
      <linearGradient id="paint0_linear_4094_28523" x1="43.9296" y1="19.3107" x2="44.265" y2="88.5183" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FCFCFD"/>
        <stop offset="1" stop-color="#EEEFF3"/>
      </linearGradient>
      <linearGradient id="paint1_linear_4094_28523" x1="26.3291" y1="98.0799" x2="100.453" y2="164.792" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FCFCFD"/>
        <stop offset="1" stop-color="#E9EBEF"/>
      </linearGradient>
    </defs>
  </svg>

</template>

<script>
export default {
  name: "EmptyElements"
}
</script>

<style scoped>

</style>